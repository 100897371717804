import Vue from 'vue'
import TextHighlight from 'vue-text-highlight'
import StickyTable from 'element-ui-sticky-table'
import VueMoment from 'vue-moment'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import ElementUI from 'element-ui'
import 'bootstrap-4-grid/css/grid.css'
import '@/assets/theme/index.css'
import '@/assets/styles/app.scss'

Vue.component('el-table', StickyTable)
Vue.component('text-highlight', TextHighlight)
Vue.use(VueMoment)
Vue.use(ElementUI, { size: 'small', i18n: (key, value) => i18n.t(key, value) })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
