import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Asset Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    redirect: '/access_china',
    children: [
      {
        path: '/access_china',
        name: 'Assets Home Type',
        meta: {
          statistical: 'demand'
        },
        component: () => import(/* webpackChunkName: "home" */ '../views/Assets.vue')
      },
      {
        path: '/access_china/apply',
        name: 'Assets Apply',
        meta: {
          index: 'assets_apply'
        },
        component: () => import(/* webpackChunkName: "home" */ '../views/AssetsApply.vue')
      }
    ]
  },
  {
    path: '/asset_video',
    name: 'Asset Video',
    meta: {
      statistical: 'demand'
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/AssetsVideo.vue')
  },
  {
    path: '/player/:path',
    name: 'Player',
    props: true,
    component: () => import(/* webpackChunkName: "home" */ '../views/Player.vue')
  },
  {
    path: '/assets/:type',
    name: 'Assets',
    props: true,
    meta: {
      statistical: 'meeting'
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/Assets.vue')
  },
  {
    path: '/contact/:code',
    name: 'Contact',
    props: true,
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || {
      x: 0,
      y: 0
    }
  }
})

export default router
